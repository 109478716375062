@import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');

#landing-page {
  font-family: Poppins, sans-serif;
  .background-image {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    opacity: 0.5;
  }
}
#landing-page #title,
#landing-page .title {
  font-weight: 300;
}
#landing-page #primary-body {
  z-index: 0;
  top: 0;
  position: absolute;
  background-color: #1cbdc5;
  width: 100%;
}
#landing-page #primary-body #bar {
  z-index: 2;
  position: absolute;
  top: 0;
  height: 70px;
  width: 100%;
}
#landing-page #primary-body #bar #varty-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  width: auto;
}
#landing-page {
  .social-media {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 200px;
    z-index: 80;
    border-radius: 0px 20px 20px 0px;
    backdrop-filter: blur(10px);
    background-color: #1a1a1a55;
    padding: 15px;

    .icon {
      height: 35px;
      width: 35px;
      margin: 6px 10px;
    }
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 20vh;
    width: 100%;
    color: #ffffff;
    text-align: center;
    line-height: calc(min(15vw, 20vh));
    font-size: calc(calc(4vh) + calc(3vw));
  }
  .circular-button {
    border-radius: 40px;
    border: 2px solid white;
    //margin: 15px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
  }
  .circular-button:hover {
    border-radius: 40px;
    color: black;
    background-color: white;
  }
  #continue-button {
    color: white;
    height: 5vw;
    width: 42vw;
    border-radius: 2.5vw;
    margin: 20;
    border: 2px solid white;
    position: absolute;
  }
  #continue-button:hover {
    color: black;
    background-color: white;
  }
}

#landing-page #primary-body #varty-logo {
  width: 100%;
}

#landing-page #secondary-body {
  z-index: 2;
  top: 80%;
  position: absolute;
  height: 700px;
  width: 100%;
  overflow: hidden;
}

#landing-page #primary-body {
  .left {
    white-space: nowrap;
    text-align: left;
    color: white;
  }
  .description {
    text-align: left;
    font-size: calc(1vw + 1.8vh);
    width: max-content;
  }

  .uppercase {
    text-transform: uppercase;
  }
  #main-wrapper {
    position: absolute;
    top: calc(calc(25vh));
    width: 100%;
  }
  #middle-wrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: auto;
  }
  #video-wrapper {
    background: transparent;
    margin-left: 20px;
    margin-right: auto;
    // width: calc(calc(20vw) + calc(26vh));
    // height: calc(calc(calc(20vw) + calc(26vh)) / 1.77);
    // width:560px;
    // height:316px;
    pointer-events: none;
    color: transparent;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 5px 10px #1a1a1a;
  }
  #left-wrapper {
    width: max-content;
    margin-left: auto;
    margin-right: 10px;
  }
  #cta {
    position: relative;
    //top: calc(calc(2vw) + calc(2vh));
    top: 20px;
    width: calc(15vw + 15vh);
    height: calc(calc(15vw + 15vh) * 0.2);
    .circular-button {
      width: max-content;
    }
  }
}
#landing-page #primary-body #video {
  top: -1.5px;
  left: -1.5px;
  height: calc(100% + 3px);
  width: calc(100% + 3px);
}

// @media screen and (max-width: 1440px) {
//   #landing-page {
//     #secondary-body {
//       top: 100%;
//     }
//     #primary-body {
//       //height: 100%;

//       #main-wrapper {
//         top: 70px;
//       }

//       #middle-wrapper {
//         width: 95%;
//         display: flex;
//         flex-direction: column;
//       }

//       #right-wrapper {
//         margin-top: 30px;
//         width: 100%;
//       }
//       #video-wrapper {
//         margin: 0;
//         height: calc(40vw);
//         width: calc(40vw * 1.77);
//       }
//     }
//   }
// }
@media screen and (min-width: 1440px) {
  #landing-page {
    .title {
      //font-size: calc(1.7vw + 1.7vh);
      font-size: 50px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 20px;
    }
    .circular-button {
      font-size: 30px;
    }
    #video-wrapper {
      width: 560px;
      height: 316px;
    }
  }
}
@media screen and (max-width: 1440px) {
  #landing-page {
    .title {
      //font-size: calc(1.7vw + 1.7vh);
      font-size: 3.6vw;
      font-weight: bold;
    }
    .subtitle {
      font-size: 1.4vw;
    }
    .circular-button {
      font-size: 2vw;
    }
    #video-wrapper {
      height: calc(20vw);
      width: calc(20vw * 1.77);
    }
  }
}
#landing-page #secondary-body svg {
  position: absolute;
  left: calc(calc(50%) - calc(1280px));
  width: 2560px;
  height: 100%;
}
#landing-page #secondary-body svg .a {
  fill: #346562;
}
#landing-page #secondary-body svg .b {
  fill: #229ca0;
}
#landing-page #secondary-body #login-buttons {
  top: 0;
  position: absolute;
  width: 100%;
  height: 25%;
}
#landing-page #secondary-body #login-buttons .button {
  cursor: pointer;
  margin: auto;
  position: relative;
  top: calc(50% - min(17.5vw * 0.24, 150px * 0.24));
  width: 35vw;
  height: calc(35vw * 0.24);
  max-width: 300px;
  max-height: calc(300px * 0.24);
  border-radius: calc(17.5vw * 0.24);
  color: #ffffff;
  outline: none;
  text-align: center;
  font-family: Arial;
  font-size: calc(min(4.32vw, 36px));
  line-height: calc(min(8.64vw, 72px));
  overflow: hidden;
}

#landing-page #secondary-body #login-buttons .button-click {
  width: 100%;
  height: 100%;
  position: absolute;
}
#landing-page #secondary-body #login-buttons #left-button {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
}
#landing-page #secondary-body #login-buttons #right-button {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
}
#landing-page #secondary-body #login-buttons #center-button {
  position: absolute;
  width: 100%;
  height: 100%;
}
#landing-page #secondary-body #login-buttons #create-button {
  background: #47caf2;
}
#landing-page #secondary-body #login-buttons #join-button {
  background: #4a64ed;
}
#landing-page #secondary-body #login-buttons-text {
  top: 40%;
  position: absolute;
  width: 100%;
  height: 75%;
}
#landing-page #secondary-body #login-buttons-text .body {
  top: 0;
  margin: auto;
  position: relative;
  width: 70%;
  height: 75%;
  max-width: 600px;
}
#landing-page #secondary-body #login-buttons-text .body .title {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(min(3.5vw, 60px));
  width: 100%;
  color: #ffffff;
  text-align: center;
  line-height: calc(min(3.5vw, 60px));
  font-size: calc(min(3.5vw, 60px));
}
#landing-page #secondary-body #login-buttons-text .body .description {
  position: absolute;
  top: calc(4% + calc(min(3.5vw, 60px)));
  height: calc(96% - calc(min(3.5vw, 60px)));
  width: 100%;
  color: #ffffff;
  text-align: left;
  font-size: calc(min(2vw, 24.3px));
}
#landing-page #secondary-body #login-buttons-text .body .description #col1 {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
}
#landing-page #secondary-body #login-buttons-text .body .description #col1 {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 40%;
}
#landing-page #secondary-body #login-buttons-text #left-button-text {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
}
#landing-page #secondary-body #login-buttons-text #right-button-text {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
}
#landing-page #tertiary-body {
  z-index: 0;
  top: calc(calc(80%) + calc(400px));
  position: absolute;
  background: url('../../assets/img/squares.svg') center;
  background-color: #272727;
  height: 1500px;
  width: 100%;
}
#landing-page #tertiary-body #contact {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 80%;
}
#landing-page #privacy {
  font-size: calc(0.5vh + 0.5vw);
  color: white;
  position: absolute;
  width: 80%;
  height: 150px;
  bottom: 0px;
  left: 10%;
}
#landing-page #tertiary-body #contact .title {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  font-size: 80px;
}
#landing-page #tertiary-body #contact #left-contact {
  position: absolute;
  top: 80px;
  width: 50%;
  height: calc(100% - 80px);
  left: 0;
}
#landing-page #tertiary-body #contact #right-contact {
  position: absolute;
  top: 80px;
  width: 50%;
  height: calc(100% - 80px);
  left: 50%;
}
.scalable-form-wrapper {
  filter: opacity(70%);
  z-index: 2;
  position: absolute;
  background: green;
  height: max-content;
  width: max-content;
}
#landing-page #tertiary-body #contact .form-box {
  padding-top: 50px;
}
#landing-page #secondary-body #login-buttons #create-button:hover {
  filter: brightness(85%);
}
#landing-page #secondary-body #login-buttons #create-button:active {
  filter: brightness(80%);
}
#landing-page #secondary-body #login-buttons #join-button:hover {
  filter: brightness(85%);
}
#landing-page #secondary-body #login-buttons #join-button:active {
  filter: brightness(80%);
}
