.UnsupportedBrowser .Page {
  background: url('../../assets/img/background.jpg') no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: #ffffff;
}
.UnsupportedBrowser .Page .Body {
  background: #1a1a1a;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 70%;
  min-width: 990px;
  min-height: 450px;
}

.UnsupportedBrowser .Page .Body .Header .VartyIcon {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 90%;
  width: auto;
}
.UnsupportedBrowser .Page .Body .Header {
  position: absolute;
  height: 47%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}
.UnsupportedBrowser .Page .Body .Message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  height: 53%;
  width: 50%;
  left: 25%;
  bottom: 0;
  font-size: 10px;
  text-align: center;
}
.UnsupportedBrowser .SocialMediaDiv {
  left: 4%;
  bottom: 4%;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.UnsupportedBrowser .SocialMedia {
  margin-bottom: 15px;
  height: 37px;
  right: 4%;
  position: relative;
  width: auto;
  display: flex;
  flex-direction: row;
}
.UnsupportedBrowser .SocialMedia .Icon {
  cursor: pointer;
  height: 37px;
  width: auto;
  position: absolute;
  left: 0px;
}
.UnsupportedBrowser .SocialMedia p {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  font-size: 24px;
  left: 60px;
}

.UnsupportedBrowser .ReturnButton {
  position: absolute;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  width: 240px;
  height: 44px;
  border: 2px solid #ffffff;
  border-radius: 22px;
  font-family: Arial;
  font-size: 22px;
  color: #ffffff;
  text-align: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.UnsupportedBrowser .ReturnButton p {
  position: relative;
  top: 6px;
}

.UnsupportedBrowser .Page .Body .BrowserButtons {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.UnsupportedBrowser .Page .Body .Browser {
  cursor: pointer;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  height: 150px;
}

.UnsupportedBrowser .ReturnButton:hover {
  border-width: 3px;
}
.UnsupportedBrowser .ReturnButton:hover p {
  top: 5px;
}
.UnsupportedBrowser .enterbutton {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  height: 50px;
  width: 175px;
  border-radius: 25px;
  background: #00c2b4;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.UnsupportedBrowser p {
  position: relative;
  top: 6px;
}
.UnsupportedBrowser .enterbutton .enter {
  height: 19px;
  width: auto;
}
.UnsupportedBrowser .enterbutton:hover {
  background: #00847b;
}
