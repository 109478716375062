.UnsupportedMobile .Page {
  background: url('../../assets/img/mobilebackground.png') no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: #ffffff;
}

.UnsupportedMobile .Page .Body {
  width: 80%;
  height: auto;
  position: relative;
}

.UnsupportedMobile .Page .Body .unsupported {
  z-index: 1;
  width: 100%;
  height: auto;
}

.UnsupportedMobile .Social-Media-Div {
  z-index: 2;
  height: 14%;
  width: 50%;
  left: 25%;
  bottom: 0%;
  position: absolute;
  align-items: center;
  text-align: center;
  display: flex;
}
.UnsupportedMobile .Social-Media-Div .facebook {
  width: calc(100% / 3);
  height: 100%;
  position: absolute;
  left: 0%;
}
.UnsupportedMobile .Social-Media-Div .twitter {
  width: calc(100% / 3);
  height: 100%;
  position: absolute;
  left: 33%;
}
.UnsupportedMobile .Social-Media-Div .instagram {
  width: calc(100% / 3);
  height: 100%;
  position: absolute;
  left: 66%;
}

.UnsupportedMobile .Social-Media-Div .Icon {
  cursor: pointer;
  height: 50%;
  margin: auto;
  top: 25%;
  width: auto;
  position: relative;
}
