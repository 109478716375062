@mixin box-shadow($x, $y, $blur, $c) {
  -webkit-box-shadow: $x $y $blur $c;
  -moz-box-shadow: $x $y $blur $c;
  -ms-box-shadow: $x $y $blur $c;
  box-shadow: $x $y $blur $c;
}
$red: #d9534f;
$green: #5cb85c;

body {
  background-color: #494949 !important;
}
#main-content,
#room-content {
  margin-top: 71px;
}
.offline-profile {
  opacity: 0.5;
}
.in-call,
.not-in-call {
  border: 1px solid transparent;
}
.in-call:hover {
  @include box-shadow(0px, 0px, 8px, $red);
  border: 1px solid $red;
}
.not-in-call:hover {
  @include box-shadow(0px, 0px, 4px, $green);
  border: 1px solid $green;
}
.beer-button {
  width: 61px;
}

.modal {
  padding-top: 72px;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 100px;
  display: block;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}
